import { singleSetEntities } from "@/store/templates/entities";

export default {
  setEntities(state, { entities }) {
    singleSetEntities(state, entities);
  },
  setIsLoading(state, isLoading) {
    state.loading = isLoading;
  }
};
